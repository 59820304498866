const ContentWrapper = ({children}) => {
    return (
        <div
            className={"bg-gradient-to-br from-white/80 to-gray-500/80 w-11/12 mx-auto my-1 p-1 rounded-lg " +
                        "border border-indigo-500 shadow-white/50 shadow-sm"
            }
        >
            {children}
        </div>
    )
}

export default ContentWrapper