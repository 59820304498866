import {Fragment} from "react";
import TitledBlock from "./TitledBlock";

const renterValue = value => {
    if (Array.isArray(value)) {
        return value.map((item, index) => <Fragment key={index}>{item}<br /></Fragment>);
    } else {
        return value;
    }
}

const ListTable = ({title, content}) => {
    return (
        <TitledBlock title={title}>

            <table className="w-[98%] mx-auto">
                <tbody>
                {content.map(({title, value}, index) => (
                    <tr
                        key={index}
                        className="hover:bg-cyan-800/10 text-sm align-top border-t border-gray-600/60"
                    >
                        <th className="text-right px-2 py-1 w-3/12">
                            {title}
                        </th>
                        <td className="px-2 py-1">
                            {renterValue(value)}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </TitledBlock>
    )
}

export default ListTable