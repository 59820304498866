const IconLink = ({icon = null, className, children, target="_blank", ...rest}) => {
    return (
        <a
            className={"inline-flex items-center gap-1 mx-2 w-auto hover:bg-cyan-200/30 rounded-md px-2 py-1 " + className}
            target={target}
            rel="noreferrer"
            {...rest}
        >
            <span className="text-2xl">{icon}</span>
            <span className="underline">{children}</span>
        </a>
    )
}

export default IconLink