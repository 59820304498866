const ImageTiles = ({images, selectImage }) => {
    return (
        <div className="flex flex-wrap  justify-center w-11/12 mx-auto my-3">
            {images.map((item, index) => (
                <div
                    key={item.filename}
                    className="flex items-center justify-center w-32 h-32 hover:bg-cyan-900/70"
                    onClick={() => selectImage(index)}
                >
                    <img
                        className="max-h-[95%] max-w-[95%] border-cyan-900/80 hover:max-h-[150%] hover:max-w-[150%] hover:z-10 hover:border-8 rounded-md"
                        src={item.thumbUrl}
                    />
                </div>
            ))}
        </div>
    )
}

export default ImageTiles