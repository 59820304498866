const TagItem = ({active, onClick, children}) => (
    <button
        className={"text-xs px-2 py-0.5 m-0.5 rounded-lg hover:bg-cyan-900/90 hover:text-white " +
            (active ? "bg-cyan-800/90 text-white " : "bg-cyan-50/50 ")
        }
        onClick={onClick}
    >
        {children}
    </button>
)

const TagList = ({allTags, activeTags, setActiveTags}) => {
    const toggleTag = tag => () => {
        setActiveTags(old => {
            if (old.includes(tag)) {
                return old.filter(oldTag => oldTag !== tag);
            } else {
                return [...old, tag]
            }
        })
    }
    return (
        <div className="w-11/12 text-center mx-auto my-3">
            <TagItem active={activeTags.length === 0} onClick={() => setActiveTags([])}>Tout</TagItem>
            {allTags.map(tag => (
                    <TagItem key={tag} active={activeTags.includes(tag)} onClick={toggleTag(tag)}>
                        {tag}
                    </TagItem>
                )
            )}
        </div>
    )
}

export default TagList