import ListTable from "../components/ListTable";
import BasicBlock from "../components/BasicBlock";
import SubText from "../components/SubText";
import {useEffect, useState} from "react";
import ImageAutoSlider from "../components/ImageAutoSlider";
import TitledBlock from "../components/TitledBlock";
import ParagraphsBlock from "../components/ParagraphsBlock";
import B from "../components/B";
import {Link} from "react-router-dom";


const info_generales = {
    title: 'Informations Générales',
    content: [
        {title: "Constructeur", value: "Zygal Boats"},
        {title: "Modèle", value: "Limbo 9.9"},
        {title: "Lieu de fabrication", value: "Ramsgate, Kent, UK"},
        {title: "Année", value: "1982"}
    ]
}

const coque = {
    title: 'Coque',
    content: [
        {title: "Type de coque", value: "Monocoque"},
        {title: "Matériau de coque", value: "Fibre de verre (Âme balsa) "},
        {title: "Longueur", value: "9.9 m"},
        {title: "Largeur", value: "3.3 m"},
        {title: "Tirant d'eau", value: "0.80 m à 1.80 m"},
        {title: "Déplacement", value: "4000 Kg (environ)"},
        {title: "Type de Quille", value: "Quille relevable"},
        {title: "Matériau de Quille", value: "Acier"},
    ]
}

const moteur = {
    title: "Moteur",
    content: [
        {title: "Marque", value: "Nanni Diesel"},
        {title: "Modèle", value: "3.75HE"},
        {title: "Année", value: "2003"},
        {title: "Puissance", value: "20 CV"},
        {title: "Carburant", value: "Diesel"},
        {title: "Capacité du réservoir", value: "25 litres"},
        {title: "Hélice", value: "Hélice repliable 2 pales"}
    ]
}
const voiles = {
    title: "Voiles",
    content: [
        {title: "Pas de voile principale", value: "Aucune voile principale n'est vendue avec le bateau"},
        {title: "Génois 19m²", value: "Voile avant Génois 19m², fabriqué en 2021"},
        {title: "Génois 21m²", value: "Voile avant Génois 21m² (année inconnue)"},
        {title: "Tourmentin", value: "Voile avant Tourmentin 6m² (année inconnue)"},
        {title: "Spinnaker", value: "Voile Spinnaker (année inconnue)"}
    ]
}

const espars = {
    title: "Espars",
    content: [
        {title: "Pas de Mât", value: [
                "Aucun mât n'est vendu avec le bateau.",
                "L'ancien mât mesurait 11 m et était gréé en Sloop fractionné.",
                <Link to="/mat" className='text-gray-700 italic'>(voir photos de l'ancien mat)</Link>
            ]
        },
        {title: "Bôme", value: "Bôme aluminium 8 m"},
        {title: "Tangon", value: "1 Tangon en aluminium et 1 Tangon en bois"}
    ]
}


const cabine = {
    title: "Cabine",
    content: [
        {title: "Couchages", value: [
                "6 personnes:",
                <SubText>- 2 couchages en V à l'avant</SubText>,
                <SubText>- 1 couchage à l'arrière</SubText>,
                <SubText>- 2 couchages banquettes</SubText>,
                <SubText>- 1 mini-couchage au dessus de la banquette tribord</SubText>,
            ]
        },
        {title: "Cuisinière", value: "Four et 2 feux, à gaz, sur balancelle"},
        {title: "Évier cuisine", value: "1 évier cuisine"},
        {title: "Toilettes", value: "Toilettes et 1 lavabo dans les toilettes"},
        {title: "Mécanisme de Quille", value: "La quille relevable se lève au milieux de la table, grâce à un moteur électrique"},
        {title: "Réservoir eau douce", value: "Réservoir souple 150 litres"},
        {title: "Pompe de cale", value: "1 manuelle, 2 électriques"},
        {title: "Coffret Gaz", value: "Caisson étanche pour la bouteille de gaz, avec tuyau d'évacuation"}
    ]
}

const instruments = {
    title: "Instruments",
    content: [
        {title: "Compas", value: ["- 1 compas électronique NASA Clipper", "- 1 compas magnétique Contest"]},
        {title: "Sondeur", value: "Sondeur de profondeur NASA Clipper"},
        {title: "Speedo", value: "Loch-speedomètre NASA Clipper (Duet)"},
        {title: "Chartplotter", value: "Lecteur de cartes Standard Horizon CP180i"},
        {title: "Auto-pilot", value: "Pilote automatique AutoHelm AH800+"},
        {title: "VHF Fixe", value: "VHF Standard Horizon Explorer, GX 1800GPS/E, 25W"},
        {title: "VHF Portable", value: "VHF Cobra Marine HH600E, 6W"}

    ]
}


const electrique12v = {
    title: "Système électrique principal (12V)",
    content: [
        {title: "Batteries", value: "2 batteries marine 12V au plomb"},
        {title: "Panneau solaire", value: "Panneau solaire WattStunde 65W"},
        {title: "Régulateur", value: "Régulateur de charge SunWare FOX-350/E"},
        {title: "Inverseur", value: "Inverseur de courant ProPower semi-sinus, 220 V, 300 W"},
    ]
}

const electrique220v = {
    title: "Système élèctique solaire/lithium (220V)",
    content: [
        {title: "Batterie", value: "Batterie lithium 12 V 1280 Wh, WattStunde LIFEPO4 Lix 100 BS"},
        {title: "Panneaux solaire", value: [
                "- 1 panneau solaire WattStunde 100 W",
                "- 2 panneaux solaire SunBeam 55 W chacun"

            ]
        },
        {title: "Régulateur", value: "Régulateur de charge Victron SmartSolar MPPT 100/20"},
        {title: "Inverseur", value: "Inverseur de courant Datou pur-sinus, 220 V, 2000 W"}
    ]
}

const mouillage = {
    title: "Mouillage",
    content: [
        {title: "Ancre avant", value: "Ancre Bruce 10 kg, 10m de chaîne + 30m de corde"},
        {title: "Ancre arrière", value: "Ancre Bruce 5 kg, 15m de chaîne + 15m de corde"},
        {title: "Autre ancres", value: "Une ancre plate, une petite ancre grappin (pour l'annexe)"},
        {title: "Annexe", value: "Annexe gonflable de marque Seago"}
    ]
}



const DescriptionPage = ({imageData}) => {
    const [frontImages, setFrontImages] = useState([]);

    useEffect(() => {
        setFrontImages(imageData
            .filter(({tags}) => tags.includes('short list'))
        )
    }, [imageData]);

    return (
        <div className="w-11/12 mx-auto py-3">
            <BasicBlock className="text-center font-bold">
                Voilier Limbo 9.9, construit en 1982 par le chantier Zygal Boats au Royaume-Unis.
                <br />
                A vendre, sans Mât (et sans la voile principale).
            </BasicBlock>
            <ImageAutoSlider images={frontImages} />
            <ListTable {...info_generales} />
            <ListTable {...coque} />
            <ListTable {...moteur} />
            <ListTable {...voiles} />
            <ListTable {...espars} />
            <ListTable {...cabine} />
            <ListTable {...instruments} />
            <TitledBlock title="Le bateau est équipé de deux systèmes électriques séparés.">
                <ParagraphsBlock>
                    <p>
                        - <B>Le système électrique principal</B> est alimenté par deux batteries au plomb
                        qui sont rechargées par un panneau solaire (65W) et l'alternateur du moteur.
                        Ce système principal alimente le moteur, les instruments et l'éclairage.
                    </p>
                    <p>
                        - <B>Le second système électrique</B> est alimenté par une batterie au lithium
                        qui est rechargé par trois panneaux solaires (210W en tout)
                        et alimente un inverseur de courant pur-sinus 220V 2000W.
                    </p>
                </ParagraphsBlock>

                <br />

            </TitledBlock>
            <ListTable {...electrique12v} />
            <ListTable {...electrique220v} />
            <ListTable {...mouillage} />

        </div>
    )
}

export default DescriptionPage