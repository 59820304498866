import {useEffect, useState} from "react";

const ImageAutoSlider = ({images, speed = 2.5}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hover, setHover] = useState(false);

    useEffect(() => {
        if (hover) return;
        if (!images.length) return;
        const intervalHandle = setInterval(() => {
            setCurrentIndex(old => old < images.length - 1 ? old + 1 : 0);
        }, speed * 1000);
        return () => clearInterval(intervalHandle);
    }, [images, hover]);

    if (!images.length) return null;

    return (
        <div className="mb-3">
            <img
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                src={images[currentIndex].thumbUrl ?? images[currentIndex].url}
                className="mx-auto"
            />
        </div>
    )
}

export default ImageAutoSlider