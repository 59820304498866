import ContentWrapper from "./components/ContentWrapper";
import PageTitle from "./components/PageTitle";
import TopMenu from "./components/TopMenu";
import {Navigate, Route, Routes} from "react-router-dom";
import DescriptionPage from "./pages/DescriptionPage";
import GaleryPage from "./pages/GaleryPage";
import ContactPage from "./pages/ContactPage";
import FlagPage from "./pages/FlagPage";
import {useEffect, useState} from "react";
import {getImagesData} from "./helpers";


function App() {
    const [imageData, setImageData] = useState([]);
    const [mastImageData, setMastImageData] = useState([]);

    useEffect(() => {
        (async () => {
            const freshImageData = await getImagesData('photos')
            setImageData(freshImageData);
            const freshMastImageData = await getImagesData('mast')
            setMastImageData(freshMastImageData);
        })()
    }, []);
  return (
    <div className="App">
        <ContentWrapper>
            <div>
                <PageTitle>Voilier 9.9m - Quille Relevable</PageTitle>
                <PageTitle>Limbo 9.9 - Zygal Boats</PageTitle>
                <PageTitle>Vendu Sans Mât</PageTitle>
            </div>
            <TopMenu
                menuItems={[
                    {text: 'Description', path: 'description'},
                    {text: 'Galerie Photos', path: 'galerie'},
                    {text: 'Visites/Contact', path: 'contact'},
                    {text: 'Pavillon', path: 'pavillon'},

                ]}
            />
            <Routes>

                <Route path="/description" element={<DescriptionPage imageData={imageData}/>} />
                <Route path="/galerie" element={<GaleryPage imageData={imageData} />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/pavillon" element={<FlagPage />} />
                <Route path="/mat" element={<GaleryPage imageData={mastImageData} />} />
                <Route path="/" element={<Navigate to="/description" replace={true} />} />
            </Routes>
        </ContentWrapper>
    </div>
  );
}

export default App;
