import {useEffect, useState} from "react";
import ImageTiles from "../components/ImageTiles";
import ModalPhoto from "../components/ModalPhoto";
import TagList from "../components/TagList";

const GaleryPage = ({imageData}) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [allTags, setAllTags] = useState([]);
    const [activeTags, setActiveTags] = useState([]);
    const [activeImages, setActiveImages] = useState([])
    useEffect(() => {
        const tagSet = new Set();
        imageData.forEach(({tags}) => {
            tags.forEach(tag => tagSet.add(tag));
        })
        setAllTags([...tagSet].sort());

    }, [imageData]);

    useEffect(() => {
        const tagsFilter = tags => {
            for (let tag of tags) {
                if (activeTags.includes(tag)) return true;
            }
            return false;
        }
        if (activeTags.length === 0) setActiveImages(imageData);
        else setActiveImages(imageData.filter(({tags}) => tagsFilter(tags)))
    }, [activeTags.length, imageData]);
    return (
        <div>
            <TagList {...{allTags, activeTags, setActiveTags}} />
            <ImageTiles images={activeImages} selectImage={setSelectedImage} />

            <ModalPhoto
                images={activeImages}
                initialIndex={selectedImage}
                close={() => setSelectedImage(null)}
            />
        </div>
    )
}

export default GaleryPage