import TitledBlock from "../components/TitledBlock";
import ParagraphsBlock from "../components/ParagraphsBlock";

const registrationServices = [
    {
        url: "https://www.yachtregistration.fr/enregistrement-de-bateau-en-pologne/",
        name: "www.yachtregistration.fr",
        changeOwner: "525 €",
        deleteRegistration: "",
    },
    {
        url: "https://polish-yacht-registration.eu/fr/notre-offre",
        name: "www.polish-yacht-registration.eu",
        changeOwner: "310 €",
        deleteRegistration: "90 €",
    },
    {
        url: "https://www.mybb24.space/products/pl_changes/210600000000609009?variant=210600000000609001",
        name: "www.mybb24.space",
        changeOwner: "299 €",
        deleteRegistration: "199 €"
    },
    {
        url: "https://www.fr.polishyachtflag.com/#acheter",
        name: "www.polishyachtflag.com",
        changeOwner: "469 €",
        deleteRegistration: "50 €",
    },
    {
        url: "https://www.polishboat.com/",
        name: "www.polishboat.com",
        changeOwner: "469 €",
        deleteRegistration: "50 €"
    },
    {
        url: "https://www.polish-boat-registration.com/polish-yacht-registration-form.html",
        name: "www.polish-boat-registration.com",
        changeOwner: "395 €",
        deleteRegistration: "299 €"
    },
];

const FlagPage = () => {
    return (
        <div className="w-11/12 mx-auto">
            <TitledBlock title="Pavillon Polonais">
                <ParagraphsBlock>
                    <p>
                        Ce voilier est actuellement immatriculé sous pavillon polonais.
                    </p>
                    <p>
                        L'acquéreur peut choisir soit de conserver le pavillon polonais,
                        en enregistrant au près des autorités polonaises un changement de propriétaire
                        tout en conservant l'immatriculation actuelle.
                    </p>
                    <p>
                        Ou alternativement, l'acquéreur peut choisir de dés-immatriculer l'immatriculation polonaise actuelle du bateau.
                        Et ensuite immatriculer le bateau sous un autre pavillon (pavillon français ou autre).
                    </p>
                </ParagraphsBlock>
            </TitledBlock>

            <TitledBlock title="Licence VHF britannique">
                <ParagraphsBlock>
                    <p>
                        La licence actuelle pour les équipements radio (VHF) du bateau est une licence du Royaume-Unis.
                    </p>
                    <p>
                        Dépendant du pavillon choisis par l'acquéreur, il peut être nécessaire d'obtenir une autre licence radio,
                        auprès d'un autre état, pour les VHF fixe et mobile du bateau.
                    </p>
                </ParagraphsBlock>
            </TitledBlock>

            <TitledBlock title="Prestataire d'immatriculation">
                <ParagraphsBlock>
                    <p>
                        Le changement de propriétaire ou la dés-immatriculation d'un bateau peut être réalisé par
                        l'intermédiaire d'un prestataire d'immatriculation de bateaux.
                    </p>
                    <p>
                        La liste suivante est une liste non-exhaustive de prestataire d'immatriculation qui prennent en charge
                        le changement d'immatriculation et/ou la dés-immatriculation de bateaux ayant un pavillon polonais.
                    </p>
                    <p>
                        Les prix indiqués sont copiés depuis leurs sites respectifs à titre comparatifs.
                        Ces prix ne sont pas garantis, veuillez vous référer aux sites des prestataire pour trouver le prix effectif.
                    </p>
                </ParagraphsBlock>



                <table className="w-[98%] mx-auto bg-cyan-50/30 rounded-lg m-3 md:max-w-3xl">
                    <thead>
                    <tr>
                        <th className="px-3 py-2 text-left">
                            Prestataire
                        </th>
                        <th className="px-3 py-2 w-4/12">
                            Changement de propriétaire
                        </th>
                        <th className="px-3 py-2 w-4/12">
                            Dés-immatriculation
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {registrationServices.map(({url, name, changeOwner, deleteRegistration}) => (
                        <tr key={url} className="hover:bg-cyan-800/10 text-sm align-top border-t border-gray-600/60">
                            <td className="px-3 py-1.5">
                                <a href={url} target="_blank" rel="noreferrer">{name}</a>
                            </td>
                            <td className="px-3 py-1.5 text-center">{changeOwner}</td>
                            <td className="px-3 py-1.5 text-center">{deleteRegistration}</td>
                        </tr>
                    ))}


                    </tbody>
                </table>
            </TitledBlock>

        </div>
    )
}



export default FlagPage