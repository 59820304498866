import BasicBlock from "./BasicBlock";

const TitledBlock = ({title, children, className = ''}) => {
    return (
        <BasicBlock className={className}>
            <h4 className="w-[98%] mx-auto my-2 text-lg font-bold bg-cyan-800/10 rounded-lg px-2 py-1">
                {title}
            </h4>
            {children}
        </BasicBlock>
    )
}

export default TitledBlock