import {useEffect, useState} from "react";
import {IconButton} from "@material-tailwind/react";
import {
    BsBoxArrowUpRight,
    BsFillArrowLeftCircleFill,
    BsFillArrowRightCircleFill,
    BsFillXCircleFill
} from "react-icons/bs";

const ModalPhoto = ({images, initialIndex, close}) => {
    const [current, setCurrent] = useState(null);
    useEffect(() => {
        setCurrent(initialIndex)
    }, [initialIndex]);

    if (current === null) return null;

    const next = () => setCurrent(old => old < images.length - 1 ? old + 1 : 0)
    const prev = () => setCurrent(old => old > 0 ? old - 1 : images.length - 1 )

    return (
        <div className="flex items-center justify-center fixed top-0 left-0 w-screen h-screen bg-cyan-50/80">
            <div className="flex flex-row w-full h-full">
                <div className="flex-1" onClick={close}/>
                <div className="relative max-h-screen flex flex-col h-full">
                    <div className="flex-1" onClick={close}/>
                    <div className="relative max-h-screen ">
                        <img
                            className="max-h-screen"
                            src={images[current].url}
                        />
                        {images[current].message && (
                            <div className="fixed bottom-0 right-0 left-0 bg-cyan-200/70 text-center p-2 text-red-900">
                                {images[current].message}
                            </div>
                        )}

                        <nav className="absolute top-0 left-0 right-0 bottom-0 flex flex-row justify-between">
                            <div className="flex items-center pl-1">
                                <IconButton
                                    onClick={prev}
                                    className="text-2xl border-2 border-white/60 hover:border-white/90 hover:-top-0.5"
                                >
                                    <BsFillArrowLeftCircleFill />
                                </IconButton>

                            </div>
                            <div className="flex felx-row gap-2 p-1">
                                <a target="_blank" rel="noreferrer"  href={images[current].url}>
                                    <IconButton
                                        className="text-2xl border-2 border-white/60 hover:border-white/90 hover:-top-0.5"
                                    >
                                        <BsBoxArrowUpRight />
                                    </IconButton>
                                </a>

                                <IconButton
                                    className="text-2xl border-2 border-white/60 hover:border-white/90 hover:-top-0.5"
                                    onClick={() => close()}>
                                    <BsFillXCircleFill />
                                </IconButton>
                            </div>
                            <div className="flex items-center pr-1">
                                <IconButton
                                    className="text-2xl  border-2 border-white/60 hover:border-white/90 hover:-top-0.5"
                                    onClick={next}>
                                    <BsFillArrowRightCircleFill />
                                </IconButton>
                            </div>

                        </nav>
                    </div>

                    <div className="flex-1" onClick={close}/>
                </div>
                <div className="flex-1" onClick={close}/>
            </div>
        </div>
    )
}

export default ModalPhoto