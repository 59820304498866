import {NavLink} from "react-router-dom";

const TopMenu = ({menuItems = []}) => {
    return (
        <nav className="flex flex-col sm:flex-row border-y border-cyan-800 justify-around w-11/12 mx-auto my-3">
            {menuItems.map(({text, path}) => {
                return (
                    <NavLink
                        to={path}
                        key={path}
                        className={({isActive}) => (
                            "px-4 py-1 hover:bg-cyan-800/80 hover:text-white "
                            + (isActive ? "bg-cyan-900/90 text-white " : "")
                        )}
                    >
                        {text}
                    </NavLink>
                )
            })}
        </nav>
    )
}

export default TopMenu