import axios from 'axios'
import md5 from 'md5'
import {backendApi, backendHost, contactTokenKey} from "./pageConfig";


const oldPhotoTag = "photo ancienne";
const oldPhotosMessage = (
    <span>
        <span className="font-bold">Attention: Cette photo n'est pas récente.</span>
        <br />
        Le voilier est vendu sans mât, et les panneaux solaires ne figurent pas dans la photo.
    </span>
)

export const getImagesData = async section => {
    const response = await axios.get(backendApi + `/${section}.json`);
    return Object.values(response.data)
        .map(item => ({
            ...item,
            url: backendHost + item.path,
            thumbUrl: backendHost + item.thumbPath,
            message: item.tags.includes(oldPhotoTag) ? oldPhotosMessage : null,
        }))

}

export const sendContactForm = async data => {
    try {
        const time = Date.now();
        const token = md5(time.toString() + contactTokenKey + data.name + data.email + data.message)
        const result = await axios.post(
            backendApi + '/contact-message',
            {...data, time, token}
        );
        return (result.status === 200);
    } catch (error) {
        return false
    }

}
