import BasicBlock from "../components/BasicBlock";
import B from "../components/B";
import {useState} from "react";
import {Button, Input, Textarea} from "@material-tailwind/react";
import TitledBlock from "../components/TitledBlock";
import IconLink from "../components/IconLink";
import {TbMailShare, TbMap2, TbWorldWww} from "react-icons/tb";
import {sendContactForm} from "../helpers";


const defaultFormData = {
    name: '',
    email: '',
    phone: '',
    message: '',
}

const ContactPage = () => {
    const [formData, setFormData] = useState(defaultFormData);

    const [sendStatus, setSendStatus] = useState(null)

    const formOnChange = e => {
        const field = e.target.name;
        const value = e.target.value.substring(0, 2000).trim();
        setFormData(old => ({...old, [field]: value}));
    }
    const submitForm = async e => {
        e.preventDefault();
        if (sendStatus) return;
        setSendStatus('sending');
        const result = await sendContactForm(formData)
        if (result) setFormData(defaultFormData);
        setSendStatus(result ? 'sent' : 'error');
    }

    const submitedMessage = submitStatus => {
        if (submitStatus === 'sending') return 'Envoi en cours...';
        if (submitStatus === 'sent') return 'Message Envoyé';
        if (submitStatus === 'error') return "Une erreur s'est produite";
        return null
    }

    return (
        <div className="w-11/12 mx-auto my-3">
            <BasicBlock className="text-center">
                Le voilier est actuellement situé au port à sec de <span className="font-bold">The Dock</span> à Lanvéoc (Finistère).
                <br />
                Il peut être visité sur rendez-vous.
            </BasicBlock>
            <TitledBlock title="Visites du voilier sur rendez-vous">
                <div className="text-center">
                    Pour toute visite du bateau, veuillez prendre rendez-vous en contactant
                    <br />
                    <B>Arnaud GODART-PHILIPPE</B>
                    <div className="my-2">
                        soit par téléphone au
                        <br />
                        <B>+33 6 62 79 45 02</B>
                    </div>
                    <div className="my-2">
                        soit par e-mail via
                        <a href="https://www.thedock-brest.com/index.php/fr/contact-et-devis" target="_blank" rel="noreferrer" > la page de contact de <B>TheDock</B></a>
                        <br />
                        <IconLink
                            href="https://www.thedock-brest.com/index.php/fr/contact-et-devis"
                            icon={<TbMailShare />}
                        >
                            https://www.thedock-brest.com/index.php/fr/contact-et-devis
                        </IconLink>
                    </div>
                </div>
            </TitledBlock>
            <TitledBlock title={<>Le port à sec <B>The Dock</B></>}>
                <div className="flex flex-col justify-center">
                    <div className="text-center m-2">
                        Le Port à Sec <B>The Dock</B> est situé à l'adresse:
                    </div>

                    <BasicBlock className="px-6 font-semibold w-10/12 sm:w-72">
                        The Dock - BR
                        <br />
                        Espace Nautique
                        <br />
                        1 rue du Môle
                        <br />
                        29160 Lanvéoc
                        <br />
                        France
                    </BasicBlock>
                    <div className="flex flex-wrap justify-center gap-3 my-3">
                        <IconLink
                            icon={<TbWorldWww />}
                            href="https://www.thedock-brest.com"
                        >
                            Site web de The Dock
                        </IconLink>
                        <IconLink
                            icon={<TbMap2 />}
                            href="https://www.google.com/maps/place/THE+DOCK+-+BR+-+by+AGP+Course+au+Large/@48.292253,-4.450462,12z/data=!4m6!3m5!1s0x4816ea8d866b6dbb:0x4bcdae5442c1dd0e!8m2!3d48.2922527!4d-4.4504625!16s%2Fg%2F1vs5w3d8?hl=en&entry=ttu"
                        >
                            Voir sur google maps
                        </IconLink>
                    </div>
                    <div className="flex items-center justify-center mx-auto text-center">
                        <a
                            href="https://www.google.com/maps/place/THE+DOCK+-+BR+-+by+AGP+Course+au+Large/@48.292253,-4.450462,12z/data=!4m6!3m5!1s0x4816ea8d866b6dbb:0x4bcdae5442c1dd0e!8m2!3d48.2922527!4d-4.4504625!16s%2Fg%2F1vs5w3d8?hl=en&entry=ttu"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src="/theDockMap.png"/>
                        </a>

                    </div>
                </div>
            </TitledBlock>
            <TitledBlock title="Contacter le propriétaire du voilier">
                <div className="flex flex-col justify-center">
                    <div className="text-center m-2">
                        Vous pouvez contacter le propriétaire du voilier, Nathanaël Sirmons, soit par téléphone au
                        <br />
                        <B>+1 305 498 2469</B>
                        <br />
                        ou par e-mail via le formulaire ci-dessous
                    </div>

                    {(!sendStatus) && (
                        <form onSubmit={submitForm}>
                            <div className="my-3 mx-auto flex flex-col gap-6 md:w-[35em] bg-white/90 p-3 rounded-lg border border-cyan-700/40">
                                <Input
                                    name="name"
                                    id="name"
                                    value={formData.name}
                                    onChange={formOnChange}
                                    label="Votre nom"
                                    className="bg-white"
                                />
                                <Input
                                    name="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={formOnChange}
                                    label="Votre adresse email"
                                    className="bg-white"
                                />
                                <Input
                                    name="phone"
                                    id="phone"
                                    value={formData.phone}
                                    onChange={formOnChange}
                                    label="Votre numéro de téléphone"
                                    className="bg-white"
                                />
                                <Textarea
                                    label="Votre message"
                                    name="message"
                                    value={formData.message}
                                    onChange={formOnChange}
                                />
                                <Button type="submit">Envoyer</Button>
                            </div>


                        </form>
                    )}
                    {(!!sendStatus) && (
                        <div className="my-3 mx-auto flex flex-col gap-6 md:w-[35em] bg-white/90 p-3 rounded-lg border border-cyan-700/40">
                            <div className="text-center">{submitedMessage(sendStatus)}</div>
                            <Button onClick={() => setSendStatus(null)}>Ok</Button>
                        </div>
                    )}
                </div>



            </TitledBlock>
        </div>
    )
}

export default ContactPage